$(document).ready(() => {
	let $nav;
    let $body;

    const cacheNodes = () => {
        $nav = $('.nav-wrapper');
        $body = $('body');
    }
	const bindHandlers = () => {
		$(document).on('click', '.nav-menu', function() {
			$nav.toggleClass('-menu-open');
            $body.toggleClass('-freeze-scroll');
            $('.nav-menu').toggleClass('-absolute'); //IE10 hack
		});
		$(document).on('mouseup', function (e) {
            const $menu = $('.nav-wrapper:not(.-breadcrumbs) .nav-list');
            const $menuBtn = $('.nav-menu');
            const $menuItem = $('.nav-item');

            if ($menuItem.has(e.target).length !== 0
            	|| (!$menu.is(e.target) && $menu.has(e.target).length === 0
                && !$menuBtn.is(e.target) && $menuBtn.has(e.target).length === 0)) {
                $nav.removeClass('-menu-open');
                $body.removeClass('-freeze-scroll');
                $('.nav-menu').removeClass('-absolute'); //IE10 hack
            }
        });
		$(window).on('resize', function () {
            $nav.removeClass('-menu-open');
            $body.removeClass('-freeze-scroll');
            $('.nav-menu').removeClass('-absolute'); //IE10 hack
        });
	};
	const init = () => {
		cacheNodes();
		bindHandlers();
	};

	init();
});
